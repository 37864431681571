module controllers {
    export module tariff {
        export class tariffLookupSearchCtrl {

            static $inject = [
                '$scope',
                '$rootScope',
                '$q',
                '$uibModal',
                'entityService',
                'bsLoadingOverlayService',
                'tariffCountryService',
                'classificationValueService',
                'hsCodeService',
                'dutyTypeService',
                '$timeout',
                'menuService',
                '$state',
                'wcoTradeToolsService'
            ];
            searchAccordian: boolean = true;

            dutyTypeList: ng.resource.IResourceArray<interfaces.tariff.IDutyTypeDropDown>;
            selectedDutyType: interfaces.tariff.IDutyTypeDropDown;
            selectedCountry: interfaces.applicationcore.IDropdownModel;
            simulationDate: moment.Moment = moment.utc();
            nationalMeasureCode: string;
            description: string;
            exclusions: boolean = true;

            messages: interfaces.applicationcore.IMessage[];

            apiTariffCodes: uiGrid.IGridApi;
            
            showGrid: boolean = false;
            isLoading: boolean = false;

            tariffDetail: interfaces.tariff.INationalMeasureCodeDetail;

            showAll: boolean = false;
            selectedCode: string = "";

            hsCode: string = "010121";

            wcoTradeToolsEnabled: boolean = false;

            HSVersion: number = 2022;

            constructor(
                private $scope: ng.IScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $q: ng.IQService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private entityService: interfaces.applicationcore.IEntityService,
                private bsLoadingOverlayService,
                private tariffCountryService: interfaces.tariff.ITariffCountryService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
                private hsCodeService: interfaces.tariff.IHsCodeService,
                private dutyTypeService: interfaces.tariff.IDutyTypeService,
                public $timeout: ng.ITimeoutService,
                private menuService: interfaces.applicationcore.IMenuService,
                private $state: ng.ui.IStateService,
                private wcoTradeToolsService: interfaces.tariff.IWCOTradeToolsService
            ) {
                
                this.nationalMeasureCode = "";

                var promises: ng.IPromise<any>[] = [];
                
                var countryPromise = entityService.getEntityCountry().get({ entityId: 0 }, (result: interfaces.applicationcore.ICountry) => {
                    this.selectedCountry = <interfaces.applicationcore.IDropdownModel>{
                        Id: result.Id,
                        Code: result.Code,
                        Description: result.Description,
                        Display: result.Code + " - " + result.Description,
                        Selected: true
                    };
                    this.getDefaultDutyType(this.selectedCountry.Id);
                    this.getHSVersion();
                }).$promise;

                var verifyWCOTradeToolsEnabled = wcoTradeToolsService.VerifyWCOTradeToolsEnabled().get({ }, (result: interfaces.tariff.VerifyTradeTools) => {

                    this.wcoTradeToolsEnabled = result.isWCOTradeToolsEnabled;
                }).$promise;                

                promises.push(countryPromise);
                promises.push(verifyWCOTradeToolsEnabled);

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariff.lookup.search'
                }, () => {
                    return $q.all(promises);
                });
            }

            public simulationDateChanged(newValue: moment.Moment) {
                this.getHSVersion();
            }


            getHSVersion(){
               
                 this.hsCodeService.getHSVersions().then((hsVersion : interfaces.tariff.HSVersions[])=>{

                    var countryHSVersions = _.find(hsVersion, (x) => { return x.country == this.selectedCountry.Code });                   

                    if (countryHSVersions)
                    {
                    
                        var countryHSVersion = _.filter(countryHSVersions.HSVersion, (x) =>{
                           return this.simulationDate.toDate() >=  new Date(x.Date)});     

                        if (countryHSVersion){
                            this.HSVersion = countryHSVersion[countryHSVersion.length-1].VersioNumber;
                        }
                    }
                    else
                    {
                        if (this.simulationDate.toDate >= moment('2022/01/01').toDate)
                        {
                            this.HSVersion = 2022;
                        }
                        else
                        {
                            this.HSVersion = 2017;
                        }
                    }
                                    
                 });
              
            }

            public gvwTariffCodes: uiGrid.IGridOptions | any = {
                data: [],
                enableFiltering: false,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnMenus: false,
                enableColumnResizing: true,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                onRegisterApi: (gridApi) => { this.apiTariffCodes = gridApi; },
                columnDefs: [{
                    name: "SELECT",
                    displayName: "",
                    enableFiltering: false,
                    enableColumnResizing: false,
                    cellTemplate: `<div ng-if="row.entity.IsSelectable">
                                        <gts-tariff-detail  national-measure-code="row.entity.NationalMeasureCode" 
                                                            duty-type-code="row.entity.DutyTypeCode"
                                                            duty-type-id="row.entity.CodeTypeClassificationValueId"
                                                            country-id="row.entity.CountryId"
                                                            simulation-date="grid.appScope.tariffLookupSearchCtrl.simulationDate"
                                                            is-tarrif-search="true">
                                        </gts-tariff-detail>
                                  </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 37                    
                }, {
                    name: "CODE",
                    displayName: "Code",
                    field: "FormattedCode",
                    enableFiltering: false,
                    width: 150,
                    enableSorting: false,
                }, {
                    name: "ICON",
                    displayName: "P&R",
                    field: "PR",
                    cellTemplate: `
                    <div>
                        <center>
                            <div ng-if="row.entity.PR === 0">
                                <span class="fa fa-check" style="color: #bcd856;"></span>
                            </div>
                            <div ng-if="row.entity.PR === 1">
                                <span class="fa fa-warning" style="color: #f57f20;"></span>
                            </div>
                            <div ng-if="row.entity.PR === 2">
                                <span class="fa fa-warning" style="color: #cb2330;"></span>
                            </div>
                        </center>
                    </div>`,
                    enableFiltering: false,
                    width: 45,
                    enableSorting: false,
                }, {
                    name: "DESCRIPTION",
                    displayName: "Description",
                    field: "Description",
                    cellTemplate: `<div class="ui-grid-cell-contents" ng-click="grid.appScope.tariffLookupSearchCtrl.showDescription(row.entity)" data-toggle="tooltip" data-placement="top" title="Click to see full description.">{{row.entity.Description}}</div>`,
                    enableFiltering: false,
                    width: 650,
                    enableSorting: false,
                }, {
                    name: "DUTY",
                    displayName: "Duty",
                    field: "DutyRate",
                    enableFiltering: false,
                    width: 150,
                    enableSorting: false,
                }, {
                    name: "DutyTypeCode",
                    displayName: "Duty Type",
                    field: "DutyTypeCode",
                    enableFiltering: false,
                    width: 70,
                    enableSorting: false,
                }, {
                    name: "StatisticalUnitCode",
                    displayName: "UoM",
                    field: "StatisticalUnitCode",
                    enableFiltering: false,
                    width: 50,
                    enableSorting: false,
                }, {
                    name: "Notes",
                    displayName: "Legal Notes",
                    field: "Notes",
                    enableFiltering: false,
                    enableColumnResizing: true,
                    cellTemplate: `
                    <div class="GridButton">
                        <center>
                            <button ng-if="row.entity.Notes" title ="Legal Notes" type="button" class="btn btn-info btn-sm" ng-click="grid.appScope.tariffLookupSearchCtrl.displayNotes(grid.getCellValue(row, col))">
                                <span class="fa fa-sticky-note"></span>
                            </button>
                        </center>
                    </div>`,
                    width: 51,
                    enableSorting: false,
                    headerTooltip: "Legal Notes",
                },  {
                    name: "WCONotes",
                    displayName: "Explanatory Notes",
                    field: "Notes",
                    enableFiltering: false,
                    enableColumnResizing: true,
                    cellTemplate: `
                    <div class="GridButton" ng-if="row.entity.NationalMeasureCode.length <= 4 && row.entity.CodeTypeClassificationValueId === 163">
                        <center>
                                <wco-trade-tool-notes  
                                is-section = "row.entity.IsSection"
                                section="row.entity.Section"
                                hs-code="row.entity.NationalMeasureCode"
                                year="2022"
                                notes-type="1"
                                wco-enabled="grid.appScope.tariffLookupSearchCtrl.wcoTradeToolsEnabled"
                            >
                            </wco-trade-tool-notes>
                        </center>
                    </div>`,
                    width: 80,
                    enableSorting: false,
                    headerTooltip: "Explanatory Notes",
                },
                {
                    name: "WCOClassNotes",
                    displayName: "Classification Opinions",
                    field: "Notes",
                    enableFiltering: false,
                    enableColumnResizing: true,
                    visible: `(grid.appScope.tariffLookupSearchCtrl.wcoTradeToolsEnabled===true)`,
                    cellTemplate: `
                    <div class="GridButton" ng-if="row.entity.NationalMeasureCode.length === 6 && row.entity.CodeTypeClassificationValueId === 163">
                        <center>
                                <wco-trade-tool-notes  
                                is-section = "row.entity.IsSection"
                                section="row.entity.Section"
                                hs-code="row.entity.NationalMeasureCode"
                                year="2022"
                                notes-type="3"
                                wco-enabled="grid.appScope.tariffLookupSearchCtrl.wcoTradeToolsEnabled"
                            >
                            </wco-trade-tool-notes>
                        </center>
                    </div>`,
                    width: 90,
                    enableSorting: false,
                    headerTooltip: "Classification Opinion"
                }]
            };

            search_click() {
                this.searchAccordian = false;
                this.isLoading = true;
                this.showGrid = true;
                this.hsCodeService.getSimplifiedExpertSearchResult().query({
                    type: this.selectedDutyType.CodeTypeClassificationValueId,
                    countryId: this.selectedCountry.Id,
                    simulationDate: this.simulationDate.format("YYYYMMDDTHH:mm"),
                    code: this.nationalMeasureCode,
                    description: this.description,
                    exclusions: this.exclusions,
                    dutyTypeId: this.selectedDutyType.Id
                }, (resultList) => {

                    this.gvwTariffCodes.data = resultList;
                    this.isLoading = false;
                }, (errorResponse) => {
                    this.isLoading = false;
                    if (errorResponse.data.Messages) {
                        this.messages = [];
                    }

                    angular.forEach(errorResponse.data.Messages, (o) => {
                        angular.forEach(o.MessageItems, (n) => {
                            this.messages.push(n);
                        });
                    });
                });
            }

            getDefaultDutyType(countryId: number) {
                return this.dutyTypeService.getDefaultForCountryDropDown(countryId).get((result: interfaces.tariff.IDutyTypeDropDown) => {
                    this.selectedDutyType = <interfaces.tariff.IDutyTypeDropDown>{
                        Id: result.Id,
                        Code: result.Code,
                        Description: result.Description,
                        Display: result.Code + " - " + result.Description,
                        CodeTypeClassificationValueId: result.CodeTypeClassificationValueId,
                        Selected: true
                    };
                }).$promise;
            }

            simulationCountryChanged(model: interfaces.applicationcore.IDropdownModel) {
                this.selectedCountry = model;
                this.selectedDutyType = null;
                this.dutyTypeList = null;
                this.getDefaultDutyType(this.selectedCountry.Id);
                this.loadDutyTypes(this.selectedCountry.Id);
                this.getHSVersion();
            }

            loadDutyTypes(countryId: number) {
                return this.dutyTypeService.getForTariffSearchDropDown(countryId).query((result: ng.resource.IResourceArray<interfaces.tariff.IDutyTypeDropDown>) => {
                    this.dutyTypeList = result;
                }
                ).$promise;
            }

            loadDutyTypeData() {
                return this.loadDutyTypes(this.selectedCountry.Id);
            }

            loadCountries() {
                return this.tariffCountryService.getDropdownList().query().$promise;
            }
            
            displayNotes(notes: string) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Notes</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Notes"></p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Notes = notes;

                        $scope.ok = () => {
                            $uibModalInstance.dismiss();
                        }
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }

            showDescription(selectedItem: interfaces.tariff.INationalMeasureCodeLookup) {
                this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Code: {{Code}}</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="Description"></p>
                            </div>
                        </div>
                    `,
                    controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                        $scope.Description = selectedItem.Description;
                        $scope.Code = selectedItem.NationalMeasureCode;
                    },
                    size: "lg",
                    resolve: {
                    }
                });
            }
            
            DisplayExclusions() {
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="tariffExclusions">
                                <div class="gts-padding">
                                    <fieldset id="exclusions">
                                    <legend>
                                       Exclusions
                                    </legend>                                            
                                            
                                            <div ui-grid="gvwExclusion" ui-grid-resize-columns ui-grid-selection ui-grid-edit ui-grid-cellNav ui-grid-row-edit class="gridSmall"></div>

                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: ($scope, $uibModalInstance: angular.ui.bootstrap.IModalInstanceService, $sce) => {
                        $scope.gvwExclusion = this.gvwExclusion;

                        $scope.ok = () => {
                            $uibModalInstance.close();
                        }
                    },
                    size: "md",
                    resolve: {
                    }
                }).result;
            }

            //Duties Grid
            apiDutiesList: uiGrid.IGridApi; 
            gvwDuties: uiGrid.IGridOptions =
                {
                    data: [],
                    enableFiltering: false,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    enableCellEditOnFocus: false,
                    enableHorizontalScrollbar: 2,
                    rowEditWaitInterval: -1,
                    cellEditableCondition: false,
                    showGridFooter: true,
                    onRegisterApi: (gridApi) => { this.registerDutiesGridApi(gridApi); },
                    columnDefs: [{
                        name: "COUNTRYGROUPCODE",
                        displayName: "Country Group",
                        field: "",
                        type: "string",
                        width: 150,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.CountryGroupCode">{{row.entity.CountryGroupCode}} - {{row.entity.CountryGroupDescription}}</div>`,
                    }, {
                        name: "TAXTYPE",
                        displayName: "Tax Type",
                        field: "",
                        type: "string",
                        width: 190,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.TaxTypeCode">{{row.entity.TaxTypeCode}} - {{row.entity.TaxTypeDescription}}</div>`,
                    }, {
                        name: "FORMULA",
                        displayName: "Formula",
                        field: "Formula",
                        type: "string",
                        width: 150,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    }, {
                        name: "TYPE",
                        displayName: "Type",
                        field: "Type",
                        type: "string",
                        width: 75,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    }, {
                        name: "PREFERENCECODE",
                        displayName: "Preference Code",
                        field: "",
                        type: "string",
                        width: 85,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.PreferenceCode">{{row.entity.PreferenceCode}} - {{row.entity.PreferenceCodeDescription}}</div>`,
                    }, {
                        name: "STARTDATE",
                        displayName: "Start Date",
                        field: "StartDate",
                        width: 100,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    }, {
                        name: "ENDDATE",
                        displayName: "End Date",
                        field: "EndDate",
                        width: 100,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: "YYYY/MM/DD"}}</div>`,
                    }, {
                        name: "EXCLUSIONS",
                        displayName: "Exclusions",
                        field: "",
                        width: 85,
                            cellTemplate: ` <div class="ui-grid-cell-contents" ng-click="grid.appScope.DisplayExclusions()" ng-if="row.entity.ExclusionList.length > 0">
                                                <span class="fa fa-eye"></span>
                                            </div>`,
                    },]
                };

            registerDutiesGridApi(gridApi: uiGrid.IGridApi) {
                this.apiDutiesList = gridApi;

                this.apiDutiesList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiDutiesList.selection.selectRow(gridSelection.row.entity);
                });
            }

            //Prohibited and Restricted Items
            apiProhibitedList: uiGrid.IGridApi;
            gvwProhibited: uiGrid.IGridOptions =
                {
                    data: [],
                    enableFiltering: false,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    enableCellEditOnFocus: false,
                    enableHorizontalScrollbar: 2,
                    rowEditWaitInterval: -1,
                    cellEditableCondition: false,
                    showGridFooter: true,
                    onRegisterApi: (gridApi) => { this.registerProhibitedGridApi(gridApi); },
                    columnDefs: [{
                        name: "COUNTRYGROUP",
                        displayName: "Country Group",
                        field: "",
                        type: "string",
                        width: 150,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.CountryGroupCode">{{row.entity.CountryGroupCode}} - {{row.entity.CountryGroupDescription}}</div>`,
                    }, {
                        name: "RESTRICTIONCODE",
                        displayName: "Restriction Code",
                        field: "RestrictionCode",
                        type: "string",
                        width: 125,
                            cellTemplate: `<div class="ui-grid-cell-contents" ng-click="grid.appScope.prohibitedAndRestricted_Click(row.entity.RestrictionId)">{{MODEL_COL_FIELD}}</div>`,
                    }, {
                        name: "RESTRICTIONTEXT",
                        displayName: "Restriction Text",
                        field: "RestrictionText",
                        type: "string",
                        width: 160,
                        cellTemplate: `<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{MODEL_COL_FIELD}}</div>`,
                    }, {
                        name: "ISPROHIBITED",
                        displayName: "Is Prohibited",
                        field: "IsProhibited",
                        width: 100,
                        cellTemplate: `<div class="ui-grid-cell-contents">
                                            <div ng-if="row.entity.IsProhibited">
                                                Prohibited
                                            </div>
                                            <div ng-if="!row.entity.IsProhibited">
                                                Restricted
                                            </div>
                                        </div>`,
                    }, {
                        name: "TYPE",
                        displayName: "Type",
                        field: "Type",
                        type: "string",
                        width: 75,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                    }, {
                        name: "STARTDATE",
                        displayName: "Start Date",
                        field: "StartDate",
                        width: 100,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    }, {
                        name: "ENDDATE",
                        displayName: "End Date",
                        field: "EndDate",
                        width: 100,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                    }, {
                        name: "DETAIL",
                        displayName: "Notes",
                        field: "Detail",
                        enableFiltering: false,
                        cellTemplate: `
                            <div class="GridButton">
                                <center>
                                    <button ng-if="row.entity.Detail" type="button" class="btn btn-info btn-sm" ng-click="grid.appScope.tariffViewNotesClick(grid.getCellValue(row, col))">
                                        <span class="fa fa-sticky-note"></span>
                                    </button>
                                </center>
                            </div>`,
                        width: 60
                        }]
                };

            registerProhibitedGridApi(gridApi: uiGrid.IGridApi) {
                this.apiProhibitedList = gridApi;

                this.apiProhibitedList.cellNav.on.navigate(this.$scope, (gridSelection) => {
                    this.apiProhibitedList.selection.selectRow(gridSelection.row.entity);
                });
            }

            //Exclusions
            apiExclusionList: uiGrid.IGridApi;
            gvwExclusion: uiGrid.IGridOptions =
                {
                    data: [],
                    enableFiltering: false,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: false,
                    enableColumnResizing: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    enableCellEditOnFocus: false,
                    enableHorizontalScrollbar: 2,
                    rowEditWaitInterval: -1,
                    cellEditableCondition: false,
                    showGridFooter: true,
                    onRegisterApi: (gridApi) => { this.registerExclusionGridApi(gridApi); },
                    columnDefs: [{
                        name: "COUNTRYGROUPCODE",
                        displayName: "Country",
                        field: "",
                        type: "string",
                        width: 300 ,
                        cellTemplate: `<div class="ui-grid-cell-contents" ng-if="row.entity.CountryCode">{{row.entity.CountryCode}} - {{row.entity.CountryDescription}}</div>`,
                    },]
                };

            registerExclusionGridApi(gridApi: uiGrid.IGridApi) {
                this.apiExclusionList = gridApi;
            }

            prohibitedAndRestricted_Click(id: number) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'tariffLoading'
                }, () => {
                    return this.menuService.getGTSConnectMenuItem(585).get((result: interfaces.applicationcore.IMenu) => {
                        if (result.url) {
                            result.url += "?Id=" + id;
                            var url = this.$state.href("auth.IFrame", { url: result.url });
                            window.open(url, '_blank');
                        }
                    }).$promise;
                });
            }

            griDetail_Click(){
                this.$state.go("auth.TariffSearch.GRIDetail", {});
            }
        };

        angular.module("app").controller("tariffLookupSearchCtrl", controllers.tariff.tariffLookupSearchCtrl);
    }
}